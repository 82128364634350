import {EMPTY, Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {ConfigurationService} from 'src/app/core/config/configuration.service';
import {IProfile} from 'src/app/interfaces/profile';
import {catchError, concatMap, map} from 'rxjs/operators';
import {IPartnerData} from 'src/app/interfaces/general/profile-definitions/partner-data.interface';
import {ItemPayload} from 'src/app/interfaces/general/responses/item-payload.interface';
import {ITermVersionCheck} from 'src/app/interfaces/general/member-definitions/term-version-check.interface';
import {IPropertyValue} from 'src/app/interfaces/general/profile-definitions/property-value.interface';
import {ValidateEmailResponse} from '../../interfaces/general/responses/validate-email-response.interface';

export const LANGS: {key: string; value: string}[] = [
    {key: 'en', value: 'english'},
    {key: 'de', value: 'german'},
    {key: 'it', value: 'italian'},
    {key: 'fr', value: 'french'},
];

export const SALUTATIONS = [
    {key: 'Mrs', value: 'miss'},
    {key: 'Mr', value: 'mister'},
    {key: 'Child', value: 'child'},
    {key: 'Other', value: 'other'},
];

export const GENDER: {key: string; value: string}[] = [
    {key: 'Female', value: 'female'},
    {key: 'Male', value: 'male'},
    {key: 'Diverse', value: 'diverse'},
];

export const MARITAL_STATUS: {key: string; value: string}[] = [
    {key: 'Married', value: 'married'},
    {key: 'Single', value: 'single'},
    {key: 'Divorced', value: 'divorced'},
    {key: 'Widowed', value: 'widowed'},
];

export const REDUCTION: {key: string; value: string}[] = [
    {key: 'none', value: 'none'},
    {key: 'ga-1st', value: 'ga-1st'},
    {key: 'ga-2nd', value: 'ga-2nd'},
    {key: 'half-fare', value: 'half-fare'},
    {key: 'junior-card', value: 'junior-card'},
    {key: 'child-card', value: 'child-card'},
    {key: 'swiss-half-fare-card', value: 'swiss-half-fare-card'},
    {key: 'swiss-travel-pass', value: 'swiss-travel-pass'},
    {key: 'student', value: 'student'},
];

@Injectable({
    providedIn: 'root',
})
export class ProfileService {
    private readonly _profileApiUrl: string;

    constructor(
        private config: ConfigurationService,
        private http: HttpClient,
    ) {
        this._profileApiUrl = this.config.get('profileApiUrl');
    }

    public getProfile(): Observable<IProfile> {
        return this.http.get<IProfile>(`${this._profileApiUrl}/me`);
    }

    public getProfilePic(): Observable<string> {
        return this.http
            .get(`${this._profileApiUrl}/me/profileimage`, {
                responseType: 'text',
            })
            .pipe(
                map((res) => 'data:image/png;base64,' + res),
                catchError(() => {
                    return of(null);
                }),
            );
    }

    public deleteProfilePic(): Observable<any> {
        return this.http.delete<any>(`${this._profileApiUrl}/me/profileimage`);
    }

    public updateProfile(payload: Partial<IProfile>): Observable<any> {
        return this.http.patch<any>(`${this._profileApiUrl}/me`, payload);
    }

    public getAllImages(): Observable<any> {
        return this.http.get(`${this._profileApiUrl}/media`);
    }

    public getPartnerData(
        top = 10000,
        additionalType?: string,
    ): Observable<ItemPayload<IPartnerData>> {
        let params = new HttpParams().append('top', top);
        if (additionalType) {
            params = params.append('additionalType', additionalType);
        }
        return this.http.get<ItemPayload<IPartnerData>>(`${this._profileApiUrl}/partnerdata`, {
            params,
        });
    }

    public getPartnerDataByName(name?: string): Observable<IPartnerData> {
        return this.http.get<IPartnerData>(`${this._profileApiUrl}/partnerdata/${name}`);
    }

    public createPartnerData(partnerData: any): Observable<any> {
        return this.http.post<any>(`${this._profileApiUrl}/partnerdata`, partnerData);
    }

    public updatePartnerData(name: string, partnerData: any): Observable<any> {
        return this.http.put<any>(`${this._profileApiUrl}/partnerdata/${name}`, partnerData);
    }

    public updatePartnerDataByName(
        name: string,
        propertyValue: Partial<IPropertyValue>,
    ): Observable<any> {
        return this.http.get<IPartnerData>(`${this._profileApiUrl}/partnerdata/${name}`).pipe(
            catchError((error: HttpErrorResponse) => {
                console.error(error);
                return EMPTY;
            }),
            concatMap((partnerData) => {
                const copy = [...(partnerData.additionalProperty ?? [])];
                const index = copy.findIndex((i) => i.propertyId === propertyValue.propertyId);
                if (index === -1) return EMPTY;
                copy[index] = propertyValue as IPropertyValue;
                partnerData.additionalProperty = copy;
                return this.updatePartnerData(name, partnerData);
            }),
        );
    }

    public removePartnerDataByNameAndId(name: string, id: string): Observable<any> {
        return this.http.get<IPartnerData>(`${this._profileApiUrl}/partnerdata/${name}`).pipe(
            catchError((error: HttpErrorResponse) => {
                console.error(error);
                return EMPTY;
            }),
            concatMap((partnerData) => {
                partnerData.additionalProperty =
                    partnerData.additionalProperty?.filter((t) => {
                        return (t as any).propertyId !== id;
                    }) ?? [];
                return this.updatePartnerData(name, partnerData);
            }),
        );
    }

    public sharePartnerData(name: string): Observable<any> {
        return this.http.put<any>(`${this._profileApiUrl}/partnerdata/${name}/share`, {});
    }

    public removePartnerDataSharing(name: string): Observable<any> {
        return this.http.delete<any>(`${this._profileApiUrl}/partnerdata/${name}/share`);
    }

    public initiateDeletionProfile(): Observable<any> {
        return this.http.delete<any>(`${this._profileApiUrl}/me`);
    }

    public cancelDeletionProfile(): Observable<any> {
        return this.http.put<any>(`${this._profileApiUrl}/me/cancelDelete`, {});
    }

    public confirmDeletionProfile(): Observable<any> {
        return this.http.put<any>(`${this._profileApiUrl}/me/confirmDelete`, {});
    }

    public verifyProfile(key: string): Observable<any> {
        return this.http.put<any>(`${this._profileApiUrl}/me/verify`, {verificationKey: key});
    }

    public resendVerificationMail(): Observable<any> {
        return this.http.put(`${this._profileApiUrl}/me/verify/email`, {observe: 'response'});
    }

    public getTerms(termCode: string): Observable<ITermVersionCheck> {
        return this.http.get<ITermVersionCheck>(`${this._profileApiUrl}/terms/${termCode}`).pipe(
            catchError((err) => {
                console.error(err);
                return of(null);
            }),
        );
    }

    public validateEmail(request: {email: string}): Observable<ValidateEmailResponse> {
        return this.http.put<ValidateEmailResponse>(
            `${this.config.get('profileApiUrl')}/email/validate`,
            request,
        );
    }
}
